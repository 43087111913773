import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import { Box } from '@mui/material';

import styles from '../../common/styling/styles';
import { MainText, SubText } from '../../common/texts';

const Item = ({ item }) => {
  const { asset, altText } = item.image;
  const image = asset?.gatsbyImageData;

  return (
    <Box
      sx={{
        display: { md: 'grid' },
        gridTemplateColumns: image ? '6fr 4fr' : '1fr',
        gap: 1,
      }}
    >
      <Box>
        <MainText
          textVariant="h3"
          value={item.title}
          fontColor={styles.deepGreen}
          textAlignment={image ? styles.alignStyleLLLL : styles.alignStyleCCCC}
        />

        {image && (
          <Box
            sx={{
              textAlign: 'center',
              width: '60%',
              display: { xs: 'block', md: 'none' },
              margin: 'auto',
              mt: 6,
            }}
          >
            <GatsbyImage image={image} alt={altText || ''} />
          </Box>
        )}

        <SubText
          variant="subtitle1"
          value={item.text}
          fontColor={styles.grey}
          textAlignment={image ? styles.alignStyleLLLL : styles.alignStyleCCCC}
        />
      </Box>

      {image && (
        <Box
          sx={{
            textAlign: 'center',
            width: '80%',
            display: { xs: 'none', md: 'block' },
            margin: 'auto',
            py: 5,
          }}
        >
          <GatsbyImage image={image} alt={altText || ''} />;
        </Box>
      )}
    </Box>
  );
};

export default Item;
