import React from 'react';

import { Box, Grid, Hidden, Typography } from '@mui/material';

import Body from '../../common/body/Body';
import FlippyCard from '../../common/flippingCards/FlippyCard';
import styles from '../../common/styling/styles';
import { MainText, SubText } from '../../common/texts';
import FanGoEmployeeCard from './FanGoEmployeeCard';
import TeamMemberCard from './TeamMemberCard';
import TeamMemberFlippyCard from './TeamMemberFlippyCard';

const MeetTheTeam = ({ meetTheTeam }) => {
  const { teamMembers, text: subtitle, title } = meetTheTeam;
  const remainder = teamMembers.length % 3;
  const cards = [];

  const MeetTheTeamContent = ({ teamMemberName, teamMemberJobRole }) => (
    <Box
      sx={{
        pr: 2,
        pl: 2,
        pt: 1,
        pb: 1,
        backgroundColor: 'primary.main',
        borderRadius: 2,
      }}
    >
      <Typography
        fontWeight={'bold'}
        variant="h4"
        textAlign={'center'}
        color={'white'}
      >
        {teamMemberName}
      </Typography>
      <Typography textAlign={'center'} color={'white'}>
        {teamMemberJobRole}
      </Typography>
    </Box>
  );

  let num = 0;
  teamMembers.forEach((teamMember, index) => {
    const {
      teamMemberName,
      teamMemberInformation,
      teamMemberJobRole,
      teamMemberImage,
    } = teamMember;

    const { asset } = teamMemberImage;
    const image = asset?.url;
    if (index === 0 || index === 2) {
      cards.push(
        <Hidden key={`hidden-${num}`} mdDown>
          <Grid item xs={2} />
        </Hidden>
      );
      num += 1;
    }
    cards.push(
      <FanGoEmployeeCard
        image={teamMemberImage}
        role={teamMemberJobRole}
        information={teamMemberInformation}
        name={teamMemberName}
      />
      // <FlippyCard
      //   key={teamMemberName}
      //   CardFrontContent={() =>
      //     MeetTheTeamContent({ teamMemberName, teamMemberJobRole })
      //   }
      //   cardBackImageUrl={image}
      //   cardFrontImageUrl={image}
      //   cardBackText={teamMemberInformation}
      // />
      // <TeamMemberFlippyCard
      //   key={teamMemberName}
      //   teamMemberName={teamMemberName}
      //   teamMemberJobRole={teamMemberJobRole}
      //   cardBackImageUrl={image}
      //   cardFrontImageUrl={image}
      //   cardBackText={teamMemberInformation}
      // />
    );
  });
  return (
    <Body paddingY={styles.allFive}>
      <Typography
        variant="h2"
        align="center"
        sx={{ color: styles.darkBlue, mb: 3 }}
      >
        {title}
      </Typography>
      {/* <MainText
        textVariant="h2"
        fontColor={styles.darkBlue}
        value={title}
        marginY={styles.allThree}
      /> */}
      {/* <SubText
        variant="subtitle1"
        fontColor={styles.grey}
        textAlignment={styles.alignStyleLLLL}
        value={subtitle}
      /> */}
      <Grid container justifyContent={'center'}>
        {cards.map((card) => card)}
      </Grid>
    </Body>
  );
};

export default MeetTheTeam;
