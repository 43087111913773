import React from 'react';

import Body from '../../common/body/Body';
import styles from '../../common/styling/styles';
import { MainText, SubText } from '../../common/texts';
import Items from './Items';

const CommunityItems = ({ data }) => {
  const { title, subTitle, communityItemsList } = data;

  return (
    <Body paddingY={styles.allFive} backgroundColor={styles.lightGrey}>
      <MainText textVariant="h2" fontColor={styles.darkBlue} value={title} />

      <SubText
        variant="subtitle1"
        fontColor={styles.grey}
        textAlignment={styles.alignStyleLLLL}
        value={subTitle}
      />

      <Items data={communityItemsList} />
    </Body>
  );
};

export default CommunityItems;
