import React from 'react';

import { Theme, Typography, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { FanGoTextProcessor } from '../../../../common/portableText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionTitle: {
      textAlign: 'center',
    },
    gridItemCenterContent: {
      margin: 'auto!important',
    },
  })
);

const TitleAndContent = ({
  title,
  content,
  titleColor = '#ffffff',
  titleTextAlign = 'center',
  shadeValue = '0.4',
  mobileHeight = '500px',
  desktopHeight = '600px',
  subTitle = '',
  subTitlePlainText = false,
  font = 'Domine',
  largeSubTitleSize = false,
  hideSubTitleOnMobile = false,
}) => {
  const classes = useStyles();
  const largerThanMobile = useMediaQuery(`(min-width: 435px)`);

  return (
    <div
      style={{
        minHeight: '400px',
        height: largerThanMobile ? desktopHeight : mobileHeight,
        paddingBottom: '30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          backgroundColor: `rgba(0, 0, 0, ${shadeValue})`,
          boxShadow: `0 0 100px 150px rgba(0, 0, 0, ${shadeValue})`,
          display: 'flex',
          flexDirection: 'column',
          rowGap: 30,
        }}
      >
        <Typography
          variant="h1"
          className={classes.sectionTitle}
          sx={{
            textAlign: titleTextAlign,
            color: titleColor,
            maxWidth: '1200px',
            margin: 'auto',
            fontFamily: 'Domine',
          }}
        >
          {title}
        </Typography>
        {subTitlePlainText && (
          <Typography
            sx={{ maxWidth: '1200px' }}
            lineHeight={2}
            fontFamily={font}
            variant={largeSubTitleSize ? 'h4' : null}
          >
            {subTitle}
          </Typography>
        )}
        {!subTitlePlainText && largerThanMobile && (
          <div style={{ maxWidth: '80%', margin: 'auto' }}>
            <FanGoTextProcessor
              color={titleColor}
              text={subTitle}
              textAlign={titleTextAlign}
              largeSubTitleSize={largeSubTitleSize}
            />
          </div>
        )}
        {content}
      </div>
    </div>
  );
};

export default TitleAndContent;
