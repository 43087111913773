import { graphql } from 'gatsby';
import React, { useContext } from 'react';

import StandardSEO from '../components/common/seo/StandardSEO';
import Separator from '../components/common/styling/Separator';
import styles from '../components/common/styling/styles';
import AboutUsOverview from '../components/PageComps/AboutUsPageComps/AboutUsOverview';
import CommunityItems from '../components/PageComps/AboutUsPageComps/CommunityItems';
import MeetTheTeam from '../components/PageComps/AboutUsPageComps/MeetTheTeam';
import { Form, TrustedBrandIcons } from '../components/PageComps/CommonComps';
import FullImageBackgroundHeader from '../components/PageComps/CommonComps/heroSections/FullImageBackgroundHeader';
import TitleAndContent from '../components/PageComps/CommonComps/heroSections/heroSectionComps/TitleAndContent';
import { DataContext } from '../providers/DataProvider';
import { getFormData } from '../utils/utils';

const AboutUsPage = ({ data }) => {
  const { heroSectionBackgroundImage: imageData } = useContext(DataContext);
  const { sanityAboutUsPage } = data;
  const { meetTheTeam, aboutUsOurStory } = sanityAboutUsPage;
  const { title } = aboutUsOurStory;

  const formData = getFormData({ data });

  return (
    <>
      <FullImageBackgroundHeader
        backgroundPosition="30% 10%"
        imageData={imageData}
        maxHeight="880px"
        content={
          <TitleAndContent
            mobileHeight="880px"
            desktopHeight="700px"
            shadeValue="0.5"
            title={title}
            content={<AboutUsOverview ourStory={aboutUsOurStory} />}
          />
        }
      />

      <MeetTheTeam meetTheTeam={meetTheTeam} />

      <CommunityItems data={sanityAboutUsPage.communityItems} />

      {/* <Management
        data={{
          ...sanityAboutUsPage.ourManagement,
          backgrounds: data.sanityCommonContent.backgrounds,
        }}
      /> */}

      <Form data={formData} />

      <Separator height={100} />

      <TrustedBrandIcons
        data={data.sanityCommonContent.trustedBy}
        paddingBottom={styles.allEight}
      />
    </>
  );
};

export default AboutUsPage;

export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityAboutUsPage.seo} />
);

export const query = graphql`
  query ($language: String!) {
    sanityAboutUsPage(language: { eq: $language }) {
      seo {
        name
        type
        description
        keywords
        title
      }
      aboutUsOurStory {
        title
        subTitle
        managingDirectors {
          name
          jobRole
          signature {
            asset {
              gatsbyImageData
            }
            altText
          }
        }
      }
      meetTheTeam {
        title
        text
        teamMembers {
          teamMemberName
          teamMemberInformation
          teamMemberJobRole
          teamMemberImage {
            asset {
              gatsbyImageData(
                placeholder: BLURRED
                height: 800
                formats: [WEBP]
              )
            }
            altText
          }
        }
      }
      communityItems {
        title
        subTitle
        communityItemsList {
          title
          text
          image {
            asset {
              gatsbyImageData(placeholder: DOMINANT_COLOR, height: 400)
            }
            altText
          }
        }
      }
      ourManagement {
        title
        management {
          name
          job
          bio
          image {
            asset {
              url
            }
            altText
          }
        }
      }
    }
    sanityHomePage(language: { eq: $language }) {
      pageHead {
        contactForm {
          title
          subTitle
          demoFormFields {
            key
            label
            required
            multiline
          }
          buttonName
        }
        referralOptions {
          referralOptionsList {
            optionName
            showOption
          }
        }
      }
    }
    sanityCommonContent(language: { eq: $language }) {
      trustedBy {
        title
      }
      backgrounds {
        asset {
          url
        }
        altText
      }
    }
  }
`;
