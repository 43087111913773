import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import Body from '../../common/body/Body';
import styles from '../../common/styling/styles';
import { MainText, SubText } from '../../common/texts';
import ManagingDirectorCard from './ManagingDirectorCard';

const AboutUsOverview = ({ ourStory }) => {
  const { managingDirectors, subTitle } = ourStory;

  let managingDirectorOne = null;
  let managingDirectorTwo = null;
  if (managingDirectors.length >= 2) {
    managingDirectorOne = managingDirectors[0];
    managingDirectorTwo = managingDirectors[1];
  }
  return (
    <div style={{ maxWidth: '1200px', margin: 'auto' }}>
      <Box>
        <Typography
          align="center"
          sx={{ color: styles.white, pb: 2, fontFamily: 'Domine' }}
        >
          {subTitle}
        </Typography>
      </Box>
      <Grid
        container
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Grid item xs={12} sm={5}>
          <ManagingDirectorCard managingDirectorInfo={managingDirectorOne} />
        </Grid>
        <Grid item xs={12} sm={5}>
          <ManagingDirectorCard managingDirectorInfo={managingDirectorTwo} />
        </Grid>
      </Grid>
    </div>
  );
};

export default AboutUsOverview;
