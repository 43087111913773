import { Box, Hidden, Typography } from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import styles from '../../common/styling/styles';

const ManagingDirectorCard = ({ managingDirectorInfo }) => {
  const { name, jobRole, signature } = managingDirectorInfo;
  const { asset, altText } = signature;

  return (
    <Box sx={{ pl: 1, pr: 1 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100px',
          width: '30%',
          mr: 'auto',
          ml: 'auto',
          mb: 1,
          borderRadius: 2,
        }}
      >
        <GatsbyImage
          image={asset.gatsbyImageData}
          alt={altText}
          style={{ width: '100%', height: '100%' }}
          objectFit="contain"
        />
      </Box>
      <Box
        sx={{
          pr: 2,
          pl: 2,
          pt: 1,
          pb: 1,
          backgroundColor: 'white',
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: { xs: 'flex', sm: 'none' },
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Typography
            fontWeight={'bold'}
            variant="body1"
            textAlign={'center'}
            color={styles.darkBlue}
          >
            {name}
          </Typography>
          <Typography
            variant="body2"
            textAlign={'center'}
            color={styles.darkBlue}
          >
            {jobRole}
          </Typography>
        </Box>

        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Typography
            fontWeight={'bold'}
            variant="h4"
            textAlign={'center'}
            color={styles.darkBlue}
          >
            {name}
          </Typography>
          <Typography
            variant="body1"
            textAlign={'center'}
            color={styles.darkBlue}
          >
            {jobRole}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ManagingDirectorCard;
