import { Box } from "@mui/material";
import React from "react";
import Item from "./Item";

const textBoxStyle = {
  width: "100%",
  height: "100%",
  flexDirection: "column",
  py: 4,
};

const Items = ({ data }) => {
  return (
    <Box
      sx={{
        display: { xs: "block", md: "flex" },
      }}
    >
      <Box sx={textBoxStyle}>
        {data.map((item, index) => {
          return <Item key={index} item={item} />;
        })}
      </Box>
    </Box>
  );
};

export default Items;
