import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      borderRadius: '10px 10px 10px 10px',
      cursor: 'pointer',
      boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    },
    employeeNameAndRoleContainer: {
      position: 'absolute',
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      width: '90%',
      margin: 'auto',
      bottom: 70,
      left: '5%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      borderRadius: 10,
      padding: 5,
    },
    employeeInformationContainer: {
      position: 'absolute',
      backgroundColor: 'rgba(250, 250, 250, 0.9)',
      paddingLeft: 10,
      paddingRight: 10,
      width: '100%',
      paddingTop: 30,
      bottom: 0,
      overflow: 'auto',
    },
    initialInfoContainer: {
      display: 'flex',
      height: '100%',
      borderRadius: '0 0 10px 10px',
      alignItems: 'flex-end',
    },
    buttonContainer: {
      position: 'absolute',
      top: -7,
      left: '45%',
      color: 'black',
    },
    employeeInfoText: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      color: 'black',
      // WebkitBoxOrient: 'vertical',
      // WebkitLineClamp: 3,
    },
    textContainer: {
      paddingTop: 20,
      position: 'absolute',
      top: 0,
      left: 5,
      height: '100%',
      width: '95%',
    },
  })
);

const FanGoEmployeeCard = ({ name, image, role, information }) => {
  const initialEmployeeHeightInfo = '60px';
  const [employeeInfoHeight, setEmployeeInfoHeight] = useState(
    initialEmployeeHeightInfo
  );
  const [showInformation, setShowInformation] = useState(false);

  const classes = useStyles();
  const smallMobileScreen = useMediaQuery(`(max-width: 360px)`);
  const numberOfChars = smallMobileScreen ? 25 : 70;
  let initialSentence = information.substring(0, numberOfChars);
  initialSentence += '...';

  let imageData;
  let imageAltText;
  if (image) {
    imageData = image?.asset?.gatsbyImageData;
    imageAltText = image.altText;
  }
  return (
    <Grid item xs={10} sm={6} md={4} key={name} sx={{ padding: 1 }}>
      <div
        className={classes.cardContainer}
        onClick={() => {
          setShowInformation(!showInformation);
          setEmployeeInfoHeight(
            employeeInfoHeight === initialEmployeeHeightInfo
              ? '100%'
              : initialEmployeeHeightInfo
          );
        }}
      >
        <GatsbyImage
          image={imageData}
          alt={imageAltText || ''}
          style={{
            height: '100%',
            width: '100%',
            borderRadius: '10px 10px 10px 10px',
          }}
        />
        <div className={classes.employeeNameAndRoleContainer}>
          <Typography style={{ fontWeight: 'bold' }}>{name}</Typography>
          <Typography align="center">{role}</Typography>
        </div>
        <div
          className={classes.employeeInformationContainer}
          style={{
            height: employeeInfoHeight,
            borderRadius: showInformation
              ? '10px 10px 10px 10px'
              : '0 0 10px 10px',
            transition: !showInformation
              ? 'all 0.1s linear'
              : 'all 0.5s linear',
          }}
        >
          <div className={classes.buttonContainer}>
            {showInformation && (
              <ExpandMoreIcon fontSize="large" color="inherit" />
            )}
            {!showInformation && (
              <ExpandLessIcon fontSize="large" color="inherit" />
            )}
          </div>
          <div className={classes.textContainer}>
            <Typography
              className={classes.employeeInfoText}
              style={{ whiteSpace: showInformation ? 'normal' : 'nowrap' }}
            >
              {information}
            </Typography>
          </div>
          {/* {!showInformation && (
            <div className={classes.initialInfoContainer}>
              <Typography>{initialSentence}</Typography>
            </div>
          )}
          {showInformation && <Typography>{information}</Typography>} */}
        </div>
      </div>
    </Grid>
  );
};

export default FanGoEmployeeCard;
